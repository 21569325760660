import React, { Component } from "react";
import { Panel, Tabs, Tab, } from "react-bootstrap";

// import ListBatches  from './ListBatches'
import NewGiftCard from './NewGiftCard'
import NewGiftCardA4 from './NewGiftCardA4'
import Analysis from './Analysis'

import "./Generateqr.css";

export default class Generateqr extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            batches: [],
        };
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>ASS</h1>
                <p>Attraktive Stadt Sonthofen</p>
            </div>
        );
    }

    render() {
        return (
            <div className="Generateqr">

                <Panel bsStyle="primary">
                    <Panel.Heading>
                        <Panel.Title> <h1>Gutscheine verwalten</h1></Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>




                        <Tabs defaultActiveKey="newgiftcardA4tab" id="tab" >

                            <Tab eventKey="newgiftcardA4tab" title="Generieren für Shop">
                                {this.props.isAuthenticated ?
                                    <NewGiftCardA4 isAuthenticated={this.props.isAuthenticated}></NewGiftCardA4> :
                                    this.renderLander()}
                            </Tab>

                            <Tab eventKey="newgiftcardtab" title="Generieren für Druck">
                                {this.props.isAuthenticated ?
                                    <NewGiftCard isAuthenticated={this.props.isAuthenticated} ></NewGiftCard> :
                                    this.renderLander()}
                            </Tab>

                            <Tab eventKey="analysistab" title="Auswerten">
                                {this.props.isAuthenticated ?
                                    <Analysis isAuthenticated={this.props.isAuthenticated} ></Analysis> :
                                    this.renderLander()}
                            </Tab>
                        </Tabs>

                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}
