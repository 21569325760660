import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel,
  Panel,

} from "react-bootstrap";
import { Auth } from "aws-amplify";

import LoaderButton from "../../components/LoaderButton";
import "./Signup.css";

export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      assid: "",
      email: "",
      name: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null
    };
  }

  validateForm() {
    return (
      this.state.assid.length > 0 &&
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password,
        attributes: {
          'custom:assid': this.state.assid,
          'name': this.state.name
        }
      });
      this.setState({
        newUser
      });
    } catch (e) {
      this.handleExceptions(e)
    }

    this.setState({ isLoading: false });
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      await Auth.signIn(this.state.email, this.state.password);

      this.props.userHasAuthenticated(true);
      window.location.reload()
      this.props.history.push("/");
    } catch (e) {
      this.handleExceptions(e)
      this.setState({ isLoading: false });
    }
  }

  handleResend = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.resendSignUp(this.state.email);
      this.setState({ isLoading: false });
      alert("Der Code wurde nochmals an deine E-Mailadresse geschickt.")
    } catch (e) {
      this.handleExceptions(e)
      this.setState({ isLoading: false });
    }
  }


  renderConfirmationForm() {
    return (
      <div>
        <form onSubmit={this.handleConfirmationSubmit}>
          <FormGroup controlId="confirmationCode" bsSize="large">
            <ControlLabel><span class="glyphicon glyphicon-text-background"></span> Validierungs-Code</ControlLabel>
            <FormControl
              autoFocus
              type="tel"
              value={this.state.confirmationCode}
              onChange={this.handleChange}
              placeholder="Code"
            />
            <HelpBlock>Wir haben Dir eine Email geschickt. Bitte trage hier den Code ein, der in dieser Email steht.</HelpBlock>
          </FormGroup>
          <LoaderButton
            block
            bsSize="large"
            disabled={!this.validateConfirmationForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Benutzer validieren"
            loadingText="Prüfen…"
          />
        </form>
        <form onSubmit={this.handleResend}>
          <FormGroup>
            <HelpBlock>Du hast keinen Code erhalten?</HelpBlock>
            <LoaderButton
              block
              type="submit"
              variant="link"
              isLoading={this.state.isLoading}
              text="Code nochmals anfordern"
            />
          </FormGroup>
        </form>
      </div>
    );
  }

  renderForm() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="assid" bsSize="large">
            <ControlLabel><span class="glyphicon glyphicon-star"></span> Mitgliedsnummer</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.assid}
              onChange={this.handleChange}
              placeholder="Deine ASS Mitgliedsnummer"
            />
          </FormGroup>
          <FormGroup controlId="name" bsSize="large">
            <ControlLabel><span class="glyphicon glyphicon-user"></span> Name</ControlLabel>
            <FormControl
              type="text"
              value={this.state.name}
              onChange={this.handleChange}
              placeholder="Dein Name"
            />
          </FormGroup>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel><span class="glyphicon glyphicon-envelope"></span> E-Mail</ControlLabel>
            <FormControl
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
              placeholder="Deine E-Mail Adresse"
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel><span class="glyphicon glyphicon-lock"></span> Passwort</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
              placeholder="Passwort"
            />
            <HelpBlock>Dein Passwort muss min. 8 Zeichen lang sein.</HelpBlock>
          </FormGroup>
          <FormGroup controlId="confirmPassword" bsSize="large">
            <ControlLabel>Passwort wiederholen</ControlLabel>
            <FormControl
              value={this.state.confirmPassword}
              onChange={this.handleChange}
              type="password"
              placeholder="Passwort"
            />
          </FormGroup>
          <FormGroup controlId="button" bsSize="large">
            <LoaderButton
              block
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Konto erstellen"
              loadingText="Signing up…"
            />
          </FormGroup>
          <FormGroup controlId="validieren" bsSize="large">
            <Link to="/confirm">Benutzer validieren...</Link>
            <HelpBlock>Benutze den Code aus der E-Mail, die wir dir geschickt haben.</HelpBlock>
          </FormGroup>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className="Signup">
        <Panel bsStyle="primary">
          <Panel.Heading>
            <Panel.Title> <h1>Konto erstellen</h1></Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            {this.state.newUser === null
              ? this.renderForm()
              : this.renderConfirmationForm()}
          </Panel.Body>
        </Panel>
      </div>
    );
  }

  handleExceptions(e) {
    console.log(e)

    // Confirm Exceptions
    if (e.code === 'AliasExistsException') {
      alert("Dein Konto ist bereits aktiviert.")
    }
    else if (e.code === 'CodeMismatchException') {
      alert("Der Code ist falsch. Bitte prüfe Deine Eingabe.")
    }
    else if (e.code === 'ExpiredCodeException') {
      alert('Dieser Code ist nicht mehr gültig. Bitte fordere einen neuen Code an.')
    }
    else if (e.code === 'LimitExceededException') {
      alert('Zu viele Fehlversuche. Bitte versuche es in einer Stunde nochmals.')
    }
    else if (e.code === 'TooManyFailedAttemptsException') {
      alert('Zu viele Fehlversuche. Bitte versuche es in einer Stunde nochmals.')
    }
    else if (e.code === 'TooManyRequestsException') {
      alert('Zu viele Fehlversuche. Bitte versuche es in einer Stunde nochmals.')
    }
    else if (e.code === 'UserNotFoundException') {
      alert('Bitte prüfe Deine E-Mailadresse.')
    }

    // Signup Exceptions
    else if (e.code === 'UsernameExistsException') {
      alert("Die E-Mail Adresse wird bereits verwendet.")
    }
    else if (e.message === 'PreSignUp failed with error Fehler: Bitte prüfe nochmals deine Eingabe..') {
      alert("Bitte prüfe Deine Mitgliedsnummer.")
    }
    else {
      alert('Leider ist etwas schiefgelaufen. Prüfe Deine Eingabe und versuche es noch einmal.')
    }
  }
} 