import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, HelpBlock } from "react-bootstrap";

class MyModal extends React.Component {
  render() {
    return (
      <div className="backdrop">
        <Modal show={true}>
          <Modal.Header>
            <Modal.Title>{this.props.header}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.props.message}
            {this.props.help
            ?
              <HelpBlock>{this.props.help}</HelpBlock>
            :
              <br></br>  }      
          </Modal.Body>
           
          <Modal.Footer>
          <Button onClick={this.props.onClose}>
          {this.props.buttonName}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

MyModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  header: PropTypes.string,
  help: PropTypes.string,
  buttonName: PropTypes.string.isRequired
};

export default MyModal;