import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel, Form, Panel } from "react-bootstrap";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import LoaderButton from "../../../components/LoaderButton";
import "./NewUserGroup.css";

export default class NewUserGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            assid: "",
            name: "",
            userGroup: "",
            status: true
        };
    }

    validateForm() {
        return (
            this.state.assid.length > 0 &&
            this.state.name.length > 0 &&
            (this.state.userGroup === "Admin_UserGroup" ||
                this.state.userGroup === "Retailer_UserGroup" ||
                this.state.userGroup === "Distributor_UserGroup")
        );
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleToggel = event => {
        this.setState({ status: !event.target.checked })
    }


    handleSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            await this.createUser({
                assid: this.state.assid,
                name: this.state.name,
                userGroup: this.state.userGroup,
                status: !event.target.checked
            });
            this.props.history.push("/management");
        } catch (e) {
            alert(e);
            this.setState({ isLoading: false });
        }
    }

    async createUser(newuser) {
        var options
        await Auth.currentAuthenticatedUser().then((user) => {
            let jwtToken = user.signInUserSession.idToken.jwtToken;
            options = {
                headers: {
                    Authorization: jwtToken
                },
                body: newuser
            }
        }
        ).catch(error => {
            console.log(error.response)
        });
        await API.post("alpenstadt", "/usergroups", options).then(response => {
            return response;
        }).catch(error => {
            console.log(error.response)
        })

    }


    render() {
        return (
            <div className="NewUserGroup">
                <Panel bsStyle="primary">
                    <Panel.Heading>
                        <Panel.Title> <h1>Neues Mitglied anlegen</h1></Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <Panel.Body>
                            <Panel.Title> <h2>Mitglieder verwalten</h2></Panel.Title>
                            <p>
                                Lege ein neues ASS-Mitglied an. Vergebe dazu eine von dir festgelegte Mitgliedsnummer. 
                                Wähle einen Namen für das Mitglied und bestimme, welcher Mitgliedergruppe dieses Mitglied zugehörig sein soll.
                            </p>
                            <p>
                                Wenn du willst, dass sich Benutzer direkt mit der 
                                neuen Mitgliedsnummer am System anmelden können, aktivere die Option "Mitglied aktivieren".
                            </p>
                        </Panel.Body>

                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup controlId="assid" bsSize="large">
                                <ControlLabel>Mitgliedsnummer</ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.assid}
                                    onChange={this.handleChange} />
                            </FormGroup>
                            <FormGroup controlId="name" bsSize="large">
                                <ControlLabel>Name</ControlLabel>
                                <FormControl
                                    type="text"
                                    value={this.state.name}
                                    onChange={this.handleChange} />
                            </FormGroup>
                            <FormGroup controlId="userGroup" bsSize="large">
                                <ControlLabel>Mitgliedergruppe</ControlLabel>
                                <FormControl
                                    value={this.state.userGroup}
                                    onChange={this.handleChange}
                                    //inputRef={el => this.inputEl = el}
                                    componentClass="select" placeholder="select">
                                    <option value="">Bitte wählen</option>
                                    <option value="Admin_UserGroup">Administrator</option>
                                    <option value="Retailer_UserGroup">Händler</option>
                                    <option value="Distributor_UserGroup">Ausgabestelle</option>
                                </FormControl>
                            </FormGroup>
                            <FormGroup controlId="status" bsSize="small">
                                <ControlLabel>Mitglied aktivieren?</ControlLabel>
                                <FormControl
                                    type="checkbox"
                                    ref="check_status"
                                    value={!this.state.status}
                                    onClick={this.handleToggel} />
                            </FormGroup>

                            <LoaderButton
                                block
                                bsStyle="primary"
                                bsSize="large"
                                disabled={!this.validateForm()}
                                type="submit"
                                isLoading={this.state.isLoading}
                                text="Erstellen"
                                loadingText="Erstellen…" />
                        </Form>
                    </Panel.Body>

                </Panel>
            </div>
        );
    }
}
