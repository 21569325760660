import React, { Component } from "react";
import {
    HelpBlock,
    FormGroup,
    FormControl,
    ControlLabel,
    
} from "react-bootstrap";
import { Auth } from "aws-amplify";

import LoaderButton from "../../components/LoaderButton";
import "./Confirm.css";

export default class Confirm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: "",
            confirmationCode: "",
        };
    }

    validateConfirmationForm() {
        return (
            this.state.email.length > 0 &&
            this.state.confirmationCode.length > 0
        );
    }

    validateResendForm() {
        return (
            this.state.email.length > 0
        );
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleConfirmationSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);

            this.props.userHasAuthenticated(true);
            window.location.reload()
            this.props.history.push("/login");
        } catch (e) {
            this.handleExceptions(e)
            this.setState({ isLoading: false });
        }
    }

    handleResend = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            await Auth.resendSignUp(this.state.email);
            this.setState({ isLoading: false });
            alert("Der Code wurde nochmals an deine E-Mailadresse geschickt.")
        } catch (e) {
            this.handleExceptions(e)
            this.setState({ isLoading: false });
        }
    }


    render() {
        return (
            <div className="Confirm">
                <form onSubmit={this.handleConfirmationSubmit}>
                    <FormGroup controlId="email" bsSize="large">
                        <ControlLabel><span class="glyphicon glyphicon-envelope"></span> E-Mail</ControlLabel>
                        <FormControl
                            autoFocus
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            placeholder="Deine E-Mail Adresse"
                        />
                    </FormGroup>
                    <FormGroup controlId="confirmationCode" bsSize="large">
                        <ControlLabel><span class="glyphicon glyphicon-text-background"></span> Validierungscode</ControlLabel>
                        <FormControl
                            type="tel"
                            value={this.state.confirmationCode}
                            onChange={this.handleChange}
                            placeholder="Code"
                        />
                        <HelpBlock>Wir haben Dir eine Email geschickt. Bitte trage hier den Code ein, der in dieser Email steht.</HelpBlock>
                    </FormGroup>
                    <LoaderButton
                        block
                        bsSize="large"
                        disabled={!this.validateConfirmationForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Benutzer validieren"
                        loadingText="Prüfen…"
                    />
                    <br></br>
                    <br></br>

                </form>

                <form onSubmit={this.handleResend}>
                    <FormGroup>
                        <HelpBlock>Du hast keinen Code erhalten?</HelpBlock>
                        <LoaderButton
                            block
                            type="submit"
                            variant="link"
                            disabled={!this.validateResendForm()}
                            isLoading={this.state.isLoading}
                            text="Code nochmals anfordern"
                        />
                        <HelpBlock>Gib deine E-Mailadresse ein und forder einen neuen Code an.</HelpBlock>
                    </FormGroup>
                </form>
            </div>
        );
    }

    handleExceptions(e) {
        console.log(e)
        if (e.code === 'AliasExistsException') {
            alert("Dein Konto ist bereits aktiviert.")
        }
        else if (e.code === 'CodeMismatchException') {
            alert("Der Code ist falsch. Bitte prüfe Deine Eingabe.")
        }
        else if (e.code === 'ExpiredCodeException') {
            alert('Dieser Code ist nicht mehr gültig. Bitte fordere einen neuen Code an.')
        }
        else if (e.code === 'LimitExceededException') {
            alert('Zu viele Fehlversuche. Bitte versuche es in einer Stunde nochmals.')
        }
        else if (e.code === 'TooManyFailedAttemptsException') {
            alert('Zu viele Fehlversuche. Bitte versuche es in einer Stunde nochmals.')
        }
        else if (e.code === 'TooManyRequestsException') {
            alert('Zu viele Fehlversuche. Bitte versuche es in einer Stunde nochmals.')
        }
        else if (e.code === 'UserNotFoundException') {
            alert('Bitte prüfe Deine E-Mailadresse.')
        }
        else {
            alert('Leider ist etwas schiefgelaufen. Prüfe Deine Eingabe und versuche es noch einmal.')
        }
    }
}
