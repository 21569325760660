import React from "react";
import { Route, Switch } from "react-router-dom";
// auth
import Login from "./containers/auth/Login";
import Signup from "./containers/auth/Signup";
import ChangePassword from "./containers/auth/ChangePassword";
import ResetPassword from "./containers/auth/ResetPassword";
import Settings from "./containers/auth/Settings";

// generic home, 404 and settings
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";

// administration
import NewUserGroup from "./containers/admin/management/NewUserGroup";
import Management from "./containers/admin/management/Management";
import UserGroup from "./containers/admin/management/UserGroup";

// generateqr
import Generateqr from "./containers/admin/generateqr/Generateqr";

// report
import Report from "./containers/report/ByAssid/Report";
import ReportAll from "./containers/report/All/ReportAll";



// routes
import AppliedRoute from "./components/AppliedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";

// distributor and retailer
import ValidateQRCode from "./containers/distributor/Validate";
import RedeemQRCode from "./containers/retailer/Redeem";
import Confirm from "./containers/auth/Confirm";
// import Impressum from "./containers/Impressum";
import DSGVO from "./containers/DSGVO";







export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    <UnauthenticatedRoute path="/confirm" exact component={Confirm} props={childProps} />
    <AuthenticatedRoute path="/validate" exact component={ValidateQRCode} props={childProps} />
    <AuthenticatedRoute path="/redeem" exact component={RedeemQRCode} props={childProps} />
    <AuthenticatedRoute path="/reportall" exact component={ReportAll} props={childProps} />
    <AuthenticatedRoute path="/report" exact component={Report} props={childProps} />
    <AuthenticatedRoute path="/usergroup/new" exact component={NewUserGroup} props={childProps} />
    <AuthenticatedRoute path="/generateqr" exact component={Generateqr} props={childProps} />
    <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps} />
    <AuthenticatedRoute path="/settings/password" exact component={ChangePassword} props={childProps} />
    <AuthenticatedRoute path="/management" exact component={Management} props={childProps} />
    <AuthenticatedRoute path="/usergroup/:id" exact component={UserGroup} props={childProps} />




    <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} props={childProps} />
    <Route path="/impressum" component={() => {
      window.location.href = 'https://www.alpenstadt.info/impressum.html';
      return null;
    }} />
    <Route path="/dsgvo" exact component={DSGVO} props={childProps} />
    { /* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>;

