import React, { Component } from "react";
import { API, Auth } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel, Form, Panel } from "react-bootstrap";
import LoaderButton from "../../../components/LoaderButton";
import "./Report.css";
import { jsPDF } from "jspdf";
import "jszip";
import JSZip from "jszip";
import {saveAs} from 'file-saver';




export default class Report extends Component {


    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            year: '',
            month: '',
            results: [],
            message: '',
            hasResults: false,
            show: false
        };
    }

    handleChange = event => {
        console.log(event)
        console.log(event.target.id)
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    async getRepoert(event) {
        var options
        var val = {
            year: event['year'],
            month: event['month']
        }
        await Auth.currentAuthenticatedUser().then((user) => {
            let jwtToken = user.signInUserSession.idToken.jwtToken;
            options = {
                headers: {
                    Authorization: jwtToken
                },
                body: val
            }
            console.log(JSON.stringify(options))

        }).catch(error => {
            console.log(error.response)
        })
        await API.put("alpenstadt", "/report", options).then(response => {
            this.setState({ results: response['Items'] })
            this.setState({ hasResults: true })
            this.createZip()
            console.log(response)
        }).catch(error => {
            this.setState({ message: error.response['data']['ERROR'] })
            console.log(error.response)
        })
    }

    async createZip() {

        let listForCSV = this.state.results.slice()
        let listForPDF = this.state.results.slice()
        let fileNamePrefix = 'Report-' + this.state.year + ' - ' + this.state.month;

        // CREATE EXCEL
        let ExcelJS = require('exceljs');
        let workbook = new ExcelJS.Workbook();
        let sheet = workbook.addWorksheet('Node-Cheat');
        // keep {} where you wan to skip the column
        sheet.columns = [
            { key: 'redeemedAt', header: 'Eingelöst am' },
            { key: 'redeemedByUser', header: 'Eingelöst von' },
            { key: 'redeemedByAssid', header: 'ASS ID' },
            { key: 'qrcode', header: 'Gutscheincode' },
            { key: 'giftcardValue', header: 'Betrag' },
        ];
        // keep {} where you wan to skip the row
        let data = [{ first: 'John', last: 'Doe' }, {}, { first: 'Jenny', last: 'Smith' }];

        listForCSV.forEach((item, i) => {
            sheet.addRow(item);
        });

        

        // CREATE Summe
        let sumCSV = 0
        listForCSV.forEach(element => {
            sumCSV += parseInt(element['giftcardValue'])
            element['redeemedAt'] = element['redeemedAt'].split("T")[0]
        });
        let sumdata = {
            giftcardValue: sumCSV,
        };

        sheet.addRow(sumdata);

        let buffer = await workbook.xlsx.writeBuffer();
        
        let fileNameCSV = fileNamePrefix + '.xlsx';



        /// CREATE PDF
        let pageNumber = 1;
        let itemNumber = 0;
        let sum = 0;



        listForPDF.sort(function (a, b) {
            return ('' + a.redeemedAt).localeCompare(b.redeemedAt);
        })

        let doc = new jsPDF('p', 'pt', 'A4');
        doc.addFont('ArialMS', 'Arial', 'normal');
        doc.setFont('courier', 'bold');
        doc.text(80, 50,
            "Report: " + this.state.year + "-" + this.state.month);
        doc.setFontSize(9);
        doc.setFont('courier', 'normal');
        let str = "Seite " + pageNumber + ' | ' + new Date().toISOString().slice(0, 10);
        doc.text(str, 80, doc.internal.pageSize.height - 30);
        listForPDF.forEach(function (item, i) {
            sum += parseInt(item.giftcardValue);
            doc.text(80, 80 + (itemNumber * 20),
                (item.redeemedAt.split('T')[0]) + ' | ' + item.qrcode + ' | ' + (item.giftcardValue
                    + " EUR").padStart(11, ' ') + ' | ' + item.redeemedByUser);
            itemNumber++;
            if ((i % 32) === 0 && i !== 0) {
                doc.setFontSize(12)
                doc.setFont('courier', 'bold');
                doc.text(80, 120 + (itemNumber * 20), 'Zwischensumme: ' + sum + ' EUR')
                doc.setFontSize(9);
                doc.setFont('courier', 'normal');
                pageNumber++;
                doc.addPage();
                doc.setPage(pageNumber)
                let str = "Seite " + pageNumber + ' | ' + new Date().toISOString().slice(0, 10);
                doc.text(str, 80, doc.internal.pageSize.height - 30);
                itemNumber = 0;
            }
            if (listForPDF.length - 1 === i) {
                doc.setFontSize(12)
                doc.setFont('courier', 'bold');
                doc.text(80, 120 + (itemNumber * 20), 'Summe: ' + sum + ' EUR')
                doc.setFontSize(9);
                doc.setFont('courier', 'normal');
            }
        });
        let fileNamePDF = fileNamePrefix + '.pdf';

        let FileSaver = require('file-saver');
        let zip = new JSZip();
        let blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        zip.file(fileNameCSV, blob);
        zip.file(fileNamePDF, doc.output('blob'));
        
        await zip.generateAsync({ type: 'blob'})
            .then(function (content) {  
                
                // let file = new File([content], 'name.zip', {type : "application/octet-stream ;charset=utf-8"})
                


                // const blob = new Blob([content], {type: 'application/octet-stream'});
                // var link = document.createElement("a");
                // link.download = fileNamePrefix + '.zip';
                // link.href = window.URL.createObjectURL(blob);


                // var reader = new FileReader();
                
                // reader.onload = function(e){
                //     // e.target.fileName = fileNamePrefix + '.zip'
                //     console.log(e.target.fileName);
                    
                // window.location.href = reader.result;
                // }
                // reader.readAsDataURL(file);

                const blob = new Blob([content], {type: 'application/zip'});
                
                  // Other browsers
                // Create a link pointing to the ObjectURL containing the blob
                const blobURL = (window.URL || window['webkitURL']).createObjectURL(blob);
                const tempLink = document.createElement('a');
                tempLink.style.display = 'none';
                tempLink.href = blobURL;
                tempLink.setAttribute('download', fileNamePrefix + '.zip');
                // Safari thinks _blank anchor are pop ups. We only want to set _blank
                // target if the browser does not support the HTML5 download attribute.
                // This allows you to download files in desktop safari if pop up blocking
                // is enabled.
                if (typeof tempLink.download === 'undefined') {
                    tempLink.setAttribute('target', '_blank');
                }
                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(blobURL);
                }, 100);
                
                // var link = document.createElement("a");
                // if (link.download !== undefined) { 
                //     console.log('Hier')// feature detection
                //     var blob = new Blob([content], {
                //         type : "application/zip"
                //     });
                //     var link = document.createElement('a');
                //     link.href = window.URL.createObjectURL(blob);
                //     link.download = fileNamePrefix + '.zip';
                //     link.click();
                //     var url = URL.createObjectURL(content);
                //     link.setAttribute("href", url);
                //     link.setAttribute("download", fileNamePrefix + '.zip');
                //     link.style.visibility = 'hidden';
                //     document.body.appendChild(link);
                //     link.click();
                //     document.body.removeChild(link);
                // }
            });
    }

    handleSubmit = async event => {
        console.log(event)
        event.preventDefault();
        this.setState({ isLoading: true });

        try {
            await this.getRepoert({
                year: this.state.year,
                month: this.state.month,
            });
            this.setState({ isLoading: false })
        } catch (e) {
            alert(e);
            this.setState({ isLoading: false })
        }

    }

    render() {
        return (
            <div >
                <Panel bsStyle="primary">
                    <Panel.Heading>
                        <Panel.Title> <h1>Report</h1></Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                    <p>
                            Ab September 2020 stehen Ihnen monatliche Reports als Microsoft Excel- und als PDF-Datei zur Verfügung.
                            

                    </p>
                    <p>
                    
                            Wählen Sie den gewünschten Zeitraum aus und klicken sie auf "Report anfordern". Es wird Ihnen eine Zip-Datei mit den 
                            entsprechenden Reportdateien zum Download angeboten.

                    </p>
                        <Form className="Report"
                            onSubmit={this.handleSubmit}>
                            {/* {this.state.isOpen ?
                                <MyModal
                                    onClose={this.returnHome}
                                    message={this.state.message}
                                    buttonName="Schließen"
                                    header="Info">
                                </MyModal>
                                :
                                <br></br>
                            } */}
                            <FormGroup controlId="year" bsSize="large">
                                <ControlLabel>Jahr</ControlLabel>
                                <FormControl
                                    value={this.state.year}
                                    onChange={this.handleChange}
                                    componentClass="select" placeholder="select">
                                    <option value="">Bitte wählen</option>
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                    <option value="2026">2026</option>
                                    <option value="2027">2027</option>
                                    <option value="2028">2028</option>
                                    <option value="2029">2029</option>
                                    <option value="2030">2030</option>
                                    <option value="2031">2031</option>
                                </FormControl>

                            </FormGroup>
                            <FormGroup controlId="month" bsSize="large">

                                <ControlLabel>Monat</ControlLabel>
                                <FormControl
                                    value={this.state.month}
                                    onChange={this.handleChange}
                                    componentClass="select" placeholder="select">
                                    <option value="">Bitte wählen</option>
                                    <option value="01">Januar</option>
                                    <option value="02">Februar</option>
                                    <option value="03">März</option>
                                    <option value="04">April</option>
                                    <option value="05">Mai</option>
                                    <option value="06">Juni</option>
                                    <option value="07">Juli</option>
                                    <option value="08">August</option>
                                    <option value="09">September</option>
                                    <option value="10">Oktober</option>
                                    <option value="11">November</option>
                                    <option value="12">Dezember</option>
                                </FormControl>

                            </FormGroup>
                            <LoaderButton
                                block
                                bsStyle="primary"
                                bsSize="large"
                                type="submit"
                                isLoading={this.state.isLoading}
                                text="Report anfordern"
                                loadingText="Anfordern..."
                            />


                        </Form>

                    </Panel.Body>
                    {/* {this.state.show ?
                        <Panel.Footer>
                            <Alert bsStyle="info" onDismiss={this.handleDismiss}>
                                <h4>Bitte beachten!</h4>
                                <p>
                                    {this.state.message}
                                </p>
                                <p>
                                    <Button onClick={this.handleDismiss}>Ok</Button>
                                </p>
                            </Alert>
                        </Panel.Footer>
                        :
                        <div></div>
                    } */}

                </Panel>
            </div>
        );
    }
}