import React, { Component } from "react";
import { FormGroup,  Form, Panel } from "react-bootstrap";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import LoaderButton from "../../../components/LoaderButton";
import MyModal from "../../../components/Modal";
import "./Analysis.css";

export default class Analysis extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
        };

    }

    handleSubmit = async event => {
        event.preventDefault();
        this.setState({ isLoading: true });

        try {
            await this.generateAnalysis();
            this.setState({ isOpen: true })
            this.setState({ isLoading: false })
        } catch (e) {
            alert(e);
            this.setState({ isOpen: true })
            this.setState({ isLoading: false })
        }
    }

    async generateAnalysis() {
        var options
        await Auth.currentAuthenticatedUser().then((user) => {
            let jwtToken = user.signInUserSession.idToken.jwtToken;
            options = {
                headers: {
                    Authorization: jwtToken
                },
            }
        }).catch(error => {
            console.log(error.response)
        })
        await API.get("alpenstadt", "/analysis", options).then(response => {
            this.setState({ message: response['message'] })
            return response;
        }).catch(error => {
            this.setState({ message: error.response['data']['ERROR'] })
            console.log(error.response)
        })
    }

    gotos3 = event => {
        window.open(
            'https://s3.console.aws.amazon.com/s3/home?region=eu-central-1#',
            '_blank'
        )
        this.props.history.push("/generateqr");
    }

    returnHome = event => {
        this.setState({ isOpen: false });
        this.setState({ isLoading: false })
    }

    render() {
        return (
            <div className="Analysis" >

                {this.state.isOpen ?
                    <MyModal
                        onClose={this.returnHome}
                        message={this.state.message}
                        buttonName="Ok"
                        header="Info">
                    </MyModal>
                    :
                    <br></br>
                }

                <Panel bsStyle="info">
                    <Panel.Body>
                        <Panel.Title> <h2>Auswertung erzeugen</h2></Panel.Title>
                        <p>
                            Mit einen Klick auf "Auswertung erzeugen", erhältst du eine Übersicht aller Gutscheinaktivitäten 
                            bis zu diesem Zeitpunkt. Mit einem Klick auf "Download" wirst du zu AWS S3 weitergeleitet.
                            Dort steht die von dir erzeugte Auswertung als CSV-Datei zum Download bereit.
                    </p>
                    </Panel.Body>
                    <Panel.Body>

                        < Form onSubmit={this.handleSubmit} >
                            <LoaderButton
                                block
                                bsStyle="primary"
                                bsSize="large"
                                isLoading={this.state.isLoading}
                                type="submit"
                                text="Auswertung erzeugen"
                                loadingText="erzeuge" />
                        </Form>

                        <Form onSubmit={this.gotos3}>
                            <FormGroup>
                                <LoaderButton
                                    block
                                    bsSize="large"
                                    variant="outline-primary"
                                    type="submit"
                                    text="Download"
                                ></LoaderButton>
                            </FormGroup>
                        </Form>
                    </Panel.Body>
                </Panel>
            </div >
        );
    }
}
