import React, { Component } from "react";
import {  Panel, Tabs, Tab } from "react-bootstrap";

import ListUserGroups from './ListUserGroups'
import ListUsers from './ListUsers'

import "./Management.css";

export default class Management extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            userGroups: [],
            users: []
        };
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>ASS</h1>
                <p>Attraktive Stadt Sonthofen</p>
            </div>
        );
    }

    render() {
        return (
            <div className="Management">
                <Panel bsStyle="primary">
                    <Panel.Heading>
                        <Panel.Title> <h1>Mitglieder verwalten</h1></Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <Tabs defaultActiveKey="usergroupstab" id="tab">
                            <Tab eventKey="usergroupstab" title="Mitglieder">
                                {this.props.isAuthenticated ?
                                    <ListUserGroups isAuthenticated={this.props.isAuthenticated} ></ListUserGroups> :
                                    this.renderLander()}
                            </Tab>
                            <Tab eventKey="userstab" title="Benutzer">
                                {this.props.isAuthenticated ?
                                    <ListUsers isAuthenticated={this.props.isAuthenticated}></ListUsers> :
                                    this.renderLander()}
                            </Tab>
                        </Tabs>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}
