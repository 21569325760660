export default {
    s3: {
      REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
      BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
    },
    apiGateway: {
      REGION: "eu-central-1",
      URL: "https://hrnnq8w948.execute-api.eu-central-1.amazonaws.com/prod"
    },
    cognito: {
      REGION: "eu-central-1",
      USER_POOL_ID: "eu-central-1_8dPQ4zUv9",
      APP_CLIENT_ID: "6f2q6cuf9e8678750smcurgl6s",
      IDENTITY_POOL_ID: "eu-central-1:c787a27f-5d43-46f4-b798-ac78b0934c05"
    }
  };

  // // // // Test Andi
  // export default {
  //   s3: {
  //     REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
  //     BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
  //   },
  //   apiGateway: {
  //     REGION: "eu-central-1",
  //     URL: "https://beeyreu8xk.execute-api.eu-central-1.amazonaws.com/prod"
  //   },
  //   cognito: {
  //     REGION: "eu-central-1",
  //     USER_POOL_ID: "eu-central-1_pQPTcmGYN",
  //     APP_CLIENT_ID: "2en0p49jd36vf3scgo0iq1a681",
  //     IDENTITY_POOL_ID: "eu-central-1:b1832fa1-ec56-451d-8f8a-0504fef6fdc1"
  //   }
  // };
  