import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel, Panel,  } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { Auth } from "aws-amplify";
import "./Login.css";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: ""
    };

  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.signIn(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
      window.location.reload()
    } catch (e) {
      this.handleExceptions(e)
      this.setState({ isLoading: false });
    }
  }




  render() {
    return (
      <div className="Login">
        <Panel bsStyle="primary">
          <Panel.Heading>
            <Panel.Title> <h1>Benutzer anmelden</h1></Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <form onSubmit={this.handleSubmit}>
              <FormGroup controlId="email" bsSize="large">
                <ControlLabel><span class="glyphicon glyphicon-envelope"></span> E-Mail</ControlLabel>
                <FormControl
                  autoFocus
                  type="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  placeholder="Deine E-Mail Adresse"
                />
              </FormGroup>
              <FormGroup controlId="password" bsSize="large">
                <ControlLabel><span class="glyphicon glyphicon-lock"></span> Passwort</ControlLabel>
                <FormControl
                  value={this.state.password}
                  onChange={this.handleChange}
                  type="password"
                  placeholder="Passwort"
                />
              </FormGroup>
              <Link to="/login/reset">Passwort vergessen?</Link>
              <LoaderButton
                block
                bsSize="large"
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text="Anmelden"
                loadingText="Anmeldung läuft…"
              />

            </form>
          </Panel.Body>

        </Panel>
      </div>
    );
  }

  handleExceptions(e) {
    console.log(e)

    // Confirm Exceptions
    if (e.code === 'AliasExistsException') {
      alert("Dein Konto ist bereits aktiviert.")
    }
    else if (e.code === 'CodeMismatchException') {
      alert("Der Code ist falsch. Bitte prüfe Deine Eingabe.")
    }
    else if (e.code === 'UserNotConfirmedException') {
      alert("Du musst zuerst Deine E-Mailadresse validieren. Bitte schau in Deinem Postfach nach.")
    }
    else if (e.code === 'NotAuthorizedException') {
      alert('Deine E-Mailadresse oder Dein Passwort ist falsch.')
    }
    else if (e.code === 'LimitExceededException') {
      alert('Zu viele Fehlversuche. Bitte versuche es in einer Stunde nochmals.')
    }
    else if (e.code === 'TooManyFailedAttemptsException') {
      alert('Zu viele Fehlversuche. Bitte versuche es in einer Stunde nochmals.')
    }
    else if (e.code === 'TooManyRequestsException') {
      alert('Zu viele Fehlversuche. Bitte versuche es in einer Stunde nochmals.')
    }
    else if (e.code === 'UserNotFoundException') {
      alert('Bitte prüfe Deine E-Mailadresse.')
    }

    // Signup Exceptions
    else if (e.code === 'UsernameExistsException') {
      alert("Die E-Mail Adresse wird bereits verwendet.")
    }
    else if (e.message === 'PreSignUp failed with error Fehler: Bitte prüfe nochmals deine Eingabe..') {
      alert("Bitte prüfe Deine Mitgliedsnummer.")
    }
    else {
      alert('Leider ist etwas schiefgelaufen. Prüfe Deine Eingabe und versuche es noch einmal.')
    }
  }
}
