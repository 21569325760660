import React, { Component } from "react";
import { API, Auth } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel, Form,  Panel } from "react-bootstrap";
import LoaderButton from "../../../components/LoaderButton";

import "./UserGroup.css";


export default class UserGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            assid: '',
            name: "",
            userGroup: "",
            status: true
        };
    }

    async componentDidMount() {
        try {
            const oneGroup = await this.getUserGroup();
            const { assid, name, userGroup, status } = { oneGroup };

            this.setState({
                assid,
                name,
                userGroup,
                status
            });
        } catch (e) {
            alert(e);
        }
    }

    getUserGroup() {
        Auth.currentAuthenticatedUser().then((user) => {
            let jwtToken = user.signInUserSession.idToken.jwtToken;
            const options = {
                headers: {
                    Authorization: jwtToken
                },
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                // queryStringParameters: {  // OPTIONAL
                //     'assid': this.props.match.params.id
                // }
            }
            API.get("alpenstadt", `/usergroups/${this.props.match.params.id}`, options).then(response => {
                this.setState(response['data']['Item']);
                return response['data']['Item']
            }).catch(error => {
                console.log(error.response)
            });
        });
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    validateForm() {
        return this.state.name.length > 0;
    }

    handleToggel = event => {
        this.setState({ status: event.target.checked })
    }

    async saveUserGroup(usergroup) {
        var options
        await Auth.currentAuthenticatedUser().then((user) => {
            let jwtToken = user.signInUserSession.idToken.jwtToken;
            options = {
                headers: {
                    Authorization: jwtToken
                },
                body: usergroup
            }

        }).catch(error => {
            console.log(error.response)
        })
        await API.put("alpenstadt", "/usergroups", options).then(response => {
            return response;
        })
    }

    handleSubmit = async event => {
        event.preventDefault();
        this.setState({ isLoading: true });

        try {
            await this.saveUserGroup({
                assid: this.state.assid,
                name: this.state.name,
                userGroup: this.state.userGroup,
                status: this.state.status
            });
            this.props.history.push("/management");
        } catch (e) {
            alert(e);
            this.setState({ isLoading: false });
        }
    }

    render() {
        return (
            <div className="UserGroup">
                {this.state.assid &&

                    <Panel bsStyle="primary">
                        <Panel.Heading>
                            <Panel.Title> <h1>{this.state.name}</h1></Panel.Title>
                        </Panel.Heading>

                        <Panel.Body>
                            <Panel.Body>
                                <Panel.Title> <h2>Mitgliedsnummer: {this.state.assid} </h2></Panel.Title>
                                <p>
                                    Hier kannst du den Namen des Mitglieds ändern. Außerdem kannst du das Mitglied aktivieren/deaktivieren.
                                </p>
                                <p>
                                    Anmerkung: Auch wenn die Gruppe deaktiviert ist, können die bereits angemeldeten Benutzer weiter das System verwenden.
                                    Um einzelne Benutzer zu deaktivieren, wechsle bitte in die Mitgliederverwaltung und wähle dort Benutzer aus.
                                </p>

                            </Panel.Body>


                            <Form onSubmit={this.handleSubmit}>

                                <FormGroup controlId="name" bsSize="large">
                                    <ControlLabel>Name</ControlLabel>
                                    <FormControl
                                        onChange={this.handleChange}
                                        value={this.state.name}
                                        type="text"
                                    />
                                </FormGroup>

                                <FormGroup controlId="status" bsSize="small">
                                    <ControlLabel>Aktiv?</ControlLabel>
                                    <FormControl
                                        type="checkbox"
                                        ref="check_status"
                                        //onClick={this.handleToggel}
                                        onChange={this.handleToggel}
                                        defaultChecked={this.state.status}
                                    />
                                    {this.state.status ?
                                        <small id="chekhelp" class="form-text text-muted">Aktuell ist das Mitglied aktiviert. D.h. neue Benutzer können ein Konto anlegen.</small>
                                        :
                                        <small id="chekhelp" class="form-text text-muted">Aktuell ist das Mitglied nicht aktiv. D.h. es können keine neuen Benutzer ein Konto anlegen.</small>
                                    }

                                </FormGroup>
                                <LoaderButton
                                    block
                                    bsStyle="primary"
                                    bsSize="large"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text="Speichern"
                                    loadingText="speichern…"
                                />
                            </Form>

                        </Panel.Body>
                    </Panel>
                }
            </div>
        );
    }
}
