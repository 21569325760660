import React, { Component } from "react";
import { Panel,} from "react-bootstrap";

import "./Home.css";
import signet from '../assets/signet.png';

export default class Home extends Component {
  render() {
    return (
      <div className="Home">
        <Panel bsStyle="primary">
          <Panel.Heading>
            <Panel.Title> <h1>Alpenstadt Sonthofen</h1></Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="lander">
              <img src={signet} alt="Smiley face" width="512px"/>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}
