import React, { Component, Fragment } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem, OverlayTrigger, Tooltip,  } from "react-bootstrap";
import { Auth } from "aws-amplify";
import "./App.css";
import Routes from "./Routes";


class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      userGroup: ''
    };
  }

  // Check the current session of the user and add attributes to state
  async componentDidMount() {
    try {
      const session = await Auth.currentSession();
      this.setUserGroup(session.getIdToken().decodePayload()['cognito:groups'])
      this.userHasAuthenticated(true);
    }
    catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  setUserGroup(userGroup) {
    this.setState({ userGroup: userGroup[0] })
  }


  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await Auth.signOut();

    this.userHasAuthenticated(false);

    this.props.history.push("/login");
  }



  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      !this.state.isAuthenticating &&
      <div className="App container">
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/"><span class="glyphicon glyphicon-home"></span></Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullLeft>
              {this.state.isAuthenticated && this.state.userGroup === "Admin_UserGroup"
                ? <Fragment>
                  <LinkContainer to="/generateqr">
                    <NavItem>Gutscheine verwalten</NavItem>
                  </LinkContainer>
                </Fragment>
                : <Fragment>
                </Fragment>
              }
              {this.state.isAuthenticated && (this.state.userGroup === "Admin_UserGroup" || this.state.userGroup === "Distributor_UserGroup")
                ? <Fragment>
                  <LinkContainer to="/validate">
                    <NavItem>Gutschein aktivieren</NavItem>
                  </LinkContainer>
                </Fragment>
                : <Fragment>
                </Fragment>
              }
              {this.state.isAuthenticated && (this.state.userGroup === "Admin_UserGroup" || this.state.userGroup === "Retailer_UserGroup")
                ? <Fragment>
                  <LinkContainer to="/redeem">
                    <NavItem>Gutschein einlösen</NavItem>
                  </LinkContainer>
                </Fragment>
                : <Fragment>
                </Fragment>
              }
              {this.state.isAuthenticated && (this.state.userGroup === "Retailer_UserGroup")
                ? <Fragment>
                  <LinkContainer to="/report">
                    <NavItem>Report</NavItem>
                  </LinkContainer>
                </Fragment>
                : <Fragment>
                </Fragment>
              }
              {this.state.isAuthenticated && (this.state.userGroup === "Admin_UserGroup")
                ? <Fragment>
                  <LinkContainer to="/reportall">
                    <NavItem>Report</NavItem>
                  </LinkContainer>
                </Fragment>
                : <Fragment>
                </Fragment>
              }
              {this.state.isAuthenticated && this.state.userGroup === "Admin_UserGroup"
                ? <Fragment>
                  <LinkContainer to="/management">
                    <NavItem>Mitglieder verwalten</NavItem>
                  </LinkContainer>
                </Fragment>
                : <Fragment>
                </Fragment>
              }
            </Nav>
            <Nav pullRight>

              {this.state.isAuthenticated
                ? <Fragment>
                  <OverlayTrigger
                    key={'settingsoverlay'}
                    placement={'bottom'}
                    overlay={
                      <Tooltip id={`tooltipsettings-${'bottom'}`}>
                        Mein Benutzer
                        </Tooltip>}>
                    <LinkContainer to="/settings">
                      <NavItem><span class="glyphicon glyphicon-user"></span></NavItem>
                    </LinkContainer>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={'logoutoverlay'}
                    placement={'bottom'}
                    overlay={
                      <Tooltip
                        id={`tooltiplogout-${'bottom'}`}>
                        Abmelden
                      </Tooltip>}>
                    <NavItem onClick={this.handleLogout}><span class="glyphicon glyphicon-log-out"></span></NavItem>
                  </OverlayTrigger>
                </Fragment>
                : <Fragment>
                  <LinkContainer to="/signup">
                    <NavItem><span class="glyphicon glyphicon-user"></span> Konto erstellen</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/login">
                    <NavItem><span class="glyphicon glyphicon-log-in"></span> Anmelden</NavItem>
                  </LinkContainer>
                </Fragment>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <Routes childProps={childProps} />

        <div class="navbarrr">
          <Nav bsStyle="pills">
            <NavItem eventKey={1} href="/impressum">
              Impressum
              </NavItem>
            <NavItem eventKey={2} href="/dsgvo">
              DSGVO
              </NavItem>
          </Nav>
        </div>
      </div>
    );
  }



}

export default withRouter(App);

