import React, { Component } from "react";
import { API, Auth } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel, Form, Panel } from "react-bootstrap";
import LoaderButton from "../../../components/LoaderButton";
import "./ReportAll.css";
import { jsPDF } from "jspdf";
import "jszip";
import JSZip from "jszip";


export default class ReportAll extends Component {


    constructor(props) {
        super(props);

        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleShow = this.handleShow.bind(this);

        this.state = {
            isLoading: null,
            year: '',
            month: '',
            results: [],
            message: '',
            show: false
        };
    }

    handleChange = event => {
        console.log(event)
        console.log(event.target.id)
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    async getRepoert(event) {
        var options
        var val = {
            year: event['year'],
            month: event['month']
        }
        await Auth.currentAuthenticatedUser().then((user) => {
            let jwtToken = user.signInUserSession.idToken.jwtToken;
            options = {
                headers: {
                    Authorization: jwtToken
                },
                body: val
            }
            console.log(JSON.stringify(options))

        }).catch(error => {
            console.log(error.response)
        })
        await API.put("alpenstadt", "/reportall", options).then(response => {
            // this.setState({ message: response['message'] })
            this.setState({ results: response })
            this.createZip()
            console.log(response)
            return response;
        }).catch(error => {
            this.setState({ message: error.response['data']['ERROR'] })
            console.log(error.response)
        })
    }

    async createZip() {

        const { Parser } = require('json2csv');

        var listForCSV = this.state.results.slice()
        var listForPDF = this.state.results.slice()
        var fileNamePrefix = 'Report-' + this.state.year + ' - ' + this.state.month;

        if (listForCSV.length === 0 || listForPDF.length === 0) {
            listForCSV = [
                {
                    Assid: "---",
                    Name: '---',
                    Betrag: 0,
                },
            ]
            listForPDF = [
                {
                    Assid: "---",
                    Name: '---',
                    Betrag: 0,
                },
            ]
        }

        // CREATE EXCEL
        const ExcelJS = require('exceljs');
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('Node-Cheat');

        sheet.columns = [
            { key: 'Assid', header: 'Assid' },
            { key: 'Name', header: 'Name' },
            { key: 'Betrag', header: 'Betrag' },

        ];
        listForCSV.forEach((item, i) => {
            sheet.addRow(item);
        });
        var fileNameCSV = fileNamePrefix + '.xlsx';
        const buffer = await workbook.xlsx.writeBuffer();



        /// CREATE PDF
        var pageNumber = 1;
        var itemNumber = 0;

        var doc = new jsPDF('p', 'pt', 'A4');
        doc.addFont('ArialMS', 'Arial', 'normal');
        doc.setFont('courier', 'bold');
        doc.text(80, 50,
            "Report: " + this.state.year + "-" + this.state.month);
        doc.setFontSize(9);
        doc.setFont('courier', 'normal');
        var str = "Seite " + pageNumber + ' | ' + new Date().toISOString().slice(0, 10);
        doc.text(str, 80, doc.internal.pageSize.height - 30);
        listForPDF.forEach(function (item, i) {
            doc.text(80, 80 + (itemNumber * 20),
                item.Assid);
            doc.text(doc.internal.pageSize.width * 0.25, 80 + (itemNumber * 20),
                item.Name);
            doc.text(doc.internal.pageSize.width * 0.8, 80 + (itemNumber * 20),
                (item.Betrag + " EUR").padStart(11, ' '));
            itemNumber++;
            if ((i % 32) === 0 && i !== 0) {
                pageNumber++;
                doc.addPage();
                doc.setPage(pageNumber)
                var str = "Seite " + pageNumber + ' | ' + new Date().toISOString().slice(0, 10);
                doc.text(str, 80, doc.internal.pageSize.height - 30);
                itemNumber = 0;
            }

        });
        var fileNamePDF = fileNamePrefix + '.pdf';
        var zip = new JSZip();
        zip.file(fileNameCSV, buffer);
        zip.file(fileNamePDF, doc.output('blob'));

        await zip.generateAsync({ type: "blob" })
            .then(function (content) {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    var url = URL.createObjectURL(content);
                    link.setAttribute("href", url);
                    link.setAttribute("download", fileNamePrefix + '.zip');
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            });
    }

    handleSubmit = async event => {
        console.log(event)
        event.preventDefault();
        this.setState({ isLoading: true });

        try {
            await this.getRepoert({
                year: this.state.year,
                month: this.state.month,
            });
            this.setState({ isOpen: true })
            this.setState({ isLoading: false })
            this.setState({ show: true })
        } catch (e) {
            alert(e);
            this.setState({ isOpen: true })
            this.setState({ isLoading: false })
            this.setState({ show: true })
        }

    }

    returnHome = event => {
        this.setState({ isOpen: false })
        this.setState({ year: '', month: '' })
    }

    handleDismiss() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    render() {
        return (
            <div>
                <Panel bsStyle="primary">
                    <Panel.Heading>
                        <Panel.Title> <h1>Report</h1></Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                    <p>
                            Ab September 2020 stehen Ihnen monatliche Reports als Microsoft Excel- und als PDF-Datei zur Verfügung.
                            

                    </p>
                    <p>
                    
                            Wählen Sie den gewünschten Zeitraum aus und klicken sie auf "Report anfordern". Es wird Ihnen eine Zip-Datei mit den 
                            entsprechenden Reportdateien zum Download angeboten.

                    </p>
                        <Form className="RedeemQRCode"
                            onSubmit={this.handleSubmit}>
                            {/* {this.state.isOpen ?
                                <MyModal
                                    onClose={this.returnHome}
                                    message={this.state.message}
                                    buttonName="Schließen"
                                    header="Info">
                                </MyModal>
                                :
                                <br></br>
                            } */}
                            <FormGroup controlId="year" bsSize="large">
                                <ControlLabel>Jahr</ControlLabel>
                                <FormControl
                                    value={this.state.year}
                                    onChange={this.handleChange}
                                    componentClass="select" placeholder="select">
                                    <option value="">Bitte wählen</option>
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                    <option value="2026">2026</option>
                                    <option value="2027">2027</option>
                                    <option value="2028">2028</option>
                                    <option value="2029">2029</option>
                                    <option value="2030">2030</option>
                                    <option value="2031">2031</option>
                                </FormControl>

                            </FormGroup>
                            <FormGroup controlId="month" bsSize="large">

                                <ControlLabel>Monat</ControlLabel>
                                <FormControl
                                    value={this.state.month}
                                    onChange={this.handleChange}
                                    componentClass="select" placeholder="select">
                                    <option value="">Bitte wählen</option>
                                    <option value="01">Januar</option>
                                    <option value="02">Februar</option>
                                    <option value="03">März</option>
                                    <option value="04">April</option>
                                    <option value="05">Mai</option>
                                    <option value="06">Juni</option>
                                    <option value="07">Juli</option>
                                    <option value="08">August</option>
                                    <option value="09">September</option>
                                    <option value="10">Oktober</option>
                                    <option value="11">November</option>
                                    <option value="12">Dezember</option>
                                </FormControl>

                            </FormGroup>
                            <LoaderButton
                                block
                                bsStyle="primary"
                                bsSize="large"
                                type="submit"
                                isLoading={this.state.isLoading}
                                text="Report anfordern"
                                loadingText="Anfordern..."
                            />
                        </Form>
                    </Panel.Body>
                    {/* {this.state.show ?
                        <Panel.Footer>
                            <Alert bsStyle="info" onDismiss={this.handleDismiss}>
                                <h4>Bitte beachten!</h4>
                                <p>
                                    {this.state.message}
                                </p>
                                <p>
                                    <Button onClick={this.handleDismiss}>Ok</Button>
                                </p>
                            </Alert>
                        </Panel.Footer>
                        :
                        <div></div>
                    } */}

                </Panel>
            </div>
        );
    }
}