import React, { Component } from "react";
import { API, Auth } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel, Form, Alert, Button, Panel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import MyModal from "../../components/Modal";


import "./Redeem.css";


export default class RedeemQRCode extends Component {
    constructor(props) {
        super(props);

        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleShow = this.handleShow.bind(this);

        this.state = {
            isLoading: null,
            qrcode: '',
            message: '',
            show: false
        };
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    validateForm() {
        return this.state.qrcode.length > 0;
    }

    async redeemQRCode(qrcode) {
        var options
        await Auth.currentAuthenticatedUser().then((user) => {
            let jwtToken = user.signInUserSession.idToken.jwtToken;
            options = {
                headers: {
                    Authorization: jwtToken
                },
                body: qrcode
            }
            console.log(JSON.stringify(options))

        }).catch(error => {
            console.log(error.response)
        })
        await API.put("alpenstadt", "/redeem", options).then(response => {
            this.setState({ message: response['message'] })
            console.log(response)
            return response;
        }).catch(error => {
            this.setState({ message: error.response['data']['ERROR'] })
            console.log(error.response)
        })
    }

    handleSubmit = async event => {
        event.preventDefault();
        this.setState({ isLoading: true });

        try {
            await this.redeemQRCode({
                qrcode: this.state.qrcode,
            });
            this.setState({ isOpen: true })
            this.setState({ isLoading: false })
            this.setState({ show: true })
        } catch (e) {
            alert(e);
            this.setState({ isOpen: true })
            this.setState({ isLoading: false })
            this.setState({ show: true })
        }

    }

    returnHome = event => {
        this.setState({ isOpen: false })
        this.setState({ qrcode: '' })
    }

    handleDismiss() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    render() {
        return (
            <div>
                <Panel bsStyle="primary">
                    <Panel.Heading>
                        <Panel.Title> <h1>Gutscheine einlösen</h1></Panel.Title>
                    </Panel.Heading>
                    
                    <Panel.Body>
                        <Form className="RedeemQRCode"
                            onSubmit={this.handleSubmit}>
                            {this.state.isOpen ?
                                <MyModal
                                    onClose={this.returnHome}
                                    message={this.state.message}
                                    buttonName="Schließen"
                                    header="Info">
                                </MyModal>
                                :
                                <br></br>
                            }
                            <FormGroup
                                controlId="qrcode"
                                bsSize="large"
                                className="inputQrCode">
                                <ControlLabel>Gutschein Code</ControlLabel>
                                <FormControl
                                    autoFocus
                                    onChange={this.handleChange}
                                    value={this.state.qrcode}
                                    type="text"
                                    placeholder="z.B. a123-0000-1a2b3b4b5b"
                                />
                            </FormGroup>
                            <LoaderButton
                                block
                                bsStyle="primary"
                                bsSize="large"
                                disabled={!this.validateForm()}
                                type="submit"
                                isLoading={this.state.isLoading}
                                text="Einlösen"
                                loadingText="Einlösen…"
                            />
                        </Form>
                    </Panel.Body>
                    {this.state.show ?
                        <Panel.Footer>
                            <Alert bsStyle="info" onDismiss={this.handleDismiss}>
                                <h4>Bitte beachten!</h4>
                                <p>
                                    {this.state.message}
                                </p>
                                <p>
                                    <Button onClick={this.handleDismiss}>Ok</Button>
                                </p>
                            </Alert>
                        </Panel.Footer>
                        :
                        <div></div>
                    }

                </Panel>
            </div>
        );
    }
}