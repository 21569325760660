import React, { Component } from "react";
import { ListGroup, ListGroupItem, Panel, Button } from "react-bootstrap";
import { API, Auth } from "aws-amplify";

import "./ListUserGroups.css";
import MyModal from "../../../components/Modal";


export default class ListUsers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            users: [],
            isOpen: false,
            message: ''
        };
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            await this.users();
        } catch (e) {
            alert(e);
        }

        this.setState({ isLoading: false });
    }

    async users() {
        var options
        await Auth.currentAuthenticatedUser().then((user) => {
            let jwtToken = user.signInUserSession.idToken.jwtToken;
            options = {
                headers: {
                    Authorization: jwtToken
                },
            }
        }).catch(error => {
            console.log(error.response)
        })
        await API.get("alpenstadt", "/users", options).then(response => {
            console.log(response)
            console.log(response['Users'])
            this.setState({ users: response['Users'] });
            return response['Users']
        })
    }

    async enableUser(sub, enabled) {
        var options
        var val = {
            sub: sub,
            enabled: enabled
        }
        await Auth.currentAuthenticatedUser().then((user) => {
            let jwtToken = user.signInUserSession.idToken.jwtToken;
            console.log(jwtToken)
            options = {
                headers: {
                    Authorization: jwtToken
                },
                body: val
            }

        }).catch(error => {
            console.log(error.response)
        })
        console.log(options)
        await API.put("alpenstadt", "/users", options).then(response => {
            
            this.setState({ message: response['message'] })
        }).catch(error => {
            this.setState({ message: error.response['data']['ERROR'] })
            console.log(error.response)
        })
    }

    async handleEnable(sub, enabled) {
        await this.enableUser(sub, enabled)
        this.setState({ isOpen: true })

    }

    returnHome() {
        window.location.reload()
    }

    renderUsersList(users) {
        return [{}].concat(users).map(
            (user, i) =>
                (i !== 0) && (user.userstatus === 'CONFIRMED')
                    ? <div
                        key={user.email}
                        to={`/user/${user.sub}`} >
                        <ListGroupItem header={user.email}>
                            {user.assid}
                            <br></br>
                            <Button onClick={(e) => {
                                this.handleEnable(user.sub, user.enabled)
                            }}>
                                {user.enabled === true ? "Deaktivieren" : "Aktivieren"}
                            </Button>
                        </ListGroupItem>
                    </div>
                    :
                    <div key={i}></div>
        );
    }


    renderLander() {
        return (
            <div className="landerusers">
                <h1>ASS</h1>
                <p>Attraktive Stadt Sonthofen</p>
            </div>
        );
    }


    renderUsers() {
        return (
            <div className="users">
                <Panel bsStyle="info">
                    <Panel.Body>
                        <Panel.Title> <h2>Benutzer verwalten</h2></Panel.Title>

                        <ListGroup>
                            {!this.state.isLoading && this.renderUsersList(this.state.users)}
                        </ListGroup>
                    </Panel.Body>
                </Panel>

            </div>
        );
    }

    render() {
        return (
            <div className="ListUsers">
                {this.props.isAuthenticated ? this.renderUsers() : this.renderLander()}

                {this.state.isOpen ?
                    <MyModal
                        onClose={this.returnHome}
                        message={this.state.message}
                        buttonName="Schließen"
                        header="Info">
                    </MyModal>
                    :
                    <br></br>

                }
            </div>
        );
    }
}
