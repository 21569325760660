import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel, Form, Panel } from "react-bootstrap";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import LoaderButton from "../../../components/LoaderButton";
import MyModal from "../../../components/Modal";
import "./NewGiftCardA4.css";

export default class NewGiftCardA4 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            giftcardValue: 0,
            giftcardAmount: null,
            orderNumber: ""
        };
    }

    validateForm() {
        return (
            this.state.giftcardValue > 0 && this.state.giftcardAmount > 0 && this.state.giftcardAmount < 101
        );
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });

    }

    handleToggel = event => {
        this.setState({ status: !event.target.checked })
    }


    handleSubmit = async event => {
        event.preventDefault();
        this.setState({ isLoading: true });

        console.log(this.state.orderNumber);

        try {
            await this.createGiftCard({
                giftcardValue: parseInt(this.state.giftcardValue),
                giftcardAmount: parseInt(this.state.giftcardAmount),
                orderNumber: this.state.orderNumber.toString(),
            });
            this.setState({ isOpen: true })
            this.setState({ isLoading: false })
        } catch (e) {
            alert(e);
            this.setState({ isOpen: true })
            this.setState({ isLoading: false })
        }
    }

    returnHome = event => {
        this.setState({ isOpen: false });
        this.setState({ giftcardAmount: 0 });
        this.setState({ giftcardValue: 0 });
    }

    gotos3 = event => {
        window.open(
            'https://s3.console.aws.amazon.com',
            '_blank'
        )
        this.returnHome()
    }

    async createGiftCard(newGiftCard) {
        var options
        await Auth.currentAuthenticatedUser().then((user) => {
            let jwtToken = user.signInUserSession.idToken.jwtToken;
            options = {
                headers: {
                    Authorization: jwtToken
                },
                body: newGiftCard
            }
        }
        ).catch(error => {
            console.log(error.response)
        });
        await API.post("alpenstadt", "/online", options).then(response => {
            this.setState({ message: response['message'] })
            return response;
        }).catch(error => {
            this.setState({ message: error.response['data']['ERROR'] })
            console.log(error.response)
        })

    }

    gotos3 = event => {
        window.open(
            'https://s3.console.aws.amazon.com',
            '_blank'
        )
    }


    render() {
        return (
            <div className="NewQRCodeA4">

                {this.state.isOpen ?
                    <MyModal
                        onClose={this.returnHome}
                        message={this.state.message}
                        buttonName="Ok"
                        header="Info">
                    </MyModal>
                    :
                    <br></br>
                }

                <Panel bsStyle="info">
                    <Panel.Body>
                        <Panel.Title> <h2>Online Shop - Gutscheine generieren</h2></Panel.Title>
                        <p>
                            Jede Bestellung erhält anhand der Bestellnummer einen eigenen Ordner in S3. Die Gutscheine
                            liegen jeweils einzeln im Format DIN A4 zum Download bereit.
                        </p>
                    </Panel.Body>
                    <Panel.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup controlId="button" bsSize="large">

                                <FormGroup controlId="orderNumber" bsSize="large">
                                    <ControlLabel>Bestellnummer</ControlLabel>
                                    <FormControl
                                        autoFocus
                                        type="text"
                                        placeholder="Shop BestellNr."
                                        value={this.state.orderNumber}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>

                                <FormGroup controlId="giftcardValue" bsSize="large">
                                    <ControlLabel>Betrag</ControlLabel>
                                    <FormControl
                                        value={this.state.giftcardValue}
                                        onChange={this.handleChange}
                                        componentClass="select" placeholder="select">
                                        <option value="">Bitte wählen</option>
                                        <option value="5">5 €</option>
                                        <option value="10">10 €</option>
                                        <option value="20">20 €</option>
                                        <option value="25">25 €</option>
                                        <option value="44">44 €</option>
                                        <option value="50">50 €</option>
                                    </FormControl>

                                </FormGroup>

                                <FormGroup controlId="giftcardAmount" bsSize="large">
                                    <ControlLabel>Anzahl</ControlLabel>
                                    <FormControl
                                        type="number"
                                        min='0'
                                        max='100'
                                        value={this.state.giftcardAmount}
                                        onChange={this.handleChange}
                                        placeholder="Max. 100"
                                    />
                                </FormGroup>

                                <LoaderButton
                                    block
                                    bsStyle="primary"
                                    bsSize="large"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text="Generieren"
                                    loadingText="Generieren…" />

                            </FormGroup>
                        </Form>

                        <Form onSubmit={this.gotos3}>
                            <FormGroup>
                                <LoaderButton
                                    block
                                    bsSize="large"
                                    variant="outline-primary"
                                    type="submit"
                                    text="Download"
                                ></LoaderButton>
                            </FormGroup>
                        </Form>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}
