import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Panel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import "./Settings.css";

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div className="Settings">
        <Panel bsStyle="primary">
          <Panel.Heading>
            <Panel.Title> <h1>Benutzer einstellungen</h1></Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <Panel.Title> <h2>Passwort ändern</h2></Panel.Title>
          </Panel.Body>
          <Panel.Body>
            <LinkContainer to="/settings/password">
              <LoaderButton
                block
                bsSize="large"
                text="Passwort ändern"
              />
            </LinkContainer>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}
