import React, { Component } from "react";
import { ListGroup, ListGroupItem, Panel } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { API, Auth } from "aws-amplify";

//import "./ListUserGroups.css";

class ListUserGroups extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            userGroups: [],
        };
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            await this.userGroups();
        } catch (e) {
            alert(e);
        }

        this.setState({ isLoading: false });
    }

    async userGroups() {
        var options
        await Auth.currentAuthenticatedUser().then((user) => {
            let jwtToken = user.signInUserSession.idToken.jwtToken;
            options = {
                headers: {
                    Authorization: jwtToken
                },
            }
            console.log("Options")
            console.log(options)
        }).catch(error => {
            console.log(error.response)
        })
        await API.get("alpenstadt", "/usergroups", options).then(response => {
            this.setState({ userGroups: response['Items'] });
            
            console.log(response)
            return response['Items']
        })
    }


    renderUserGroupsList(userGroups) {
        return [{}].concat(userGroups).map(
            (userGroup, i) =>
                i !== 0
                    ? <LinkContainer
                        key={userGroup.assid}
                        to={`/usergroup/${userGroup.assid}`}>
                        <ListGroupItem header={userGroup.name} >
                            {this.decodeUserGroupe(userGroup.userGroup)} <br></br>
                            {userGroup.assid + " ist "}
                            {userGroup.status === true ? " aktiv" : " inaktiv"} 

                        </ListGroupItem>
                    </LinkContainer>
                    : <LinkContainer
                        key="new"
                        to="/usergroup/new">
                        <ListGroupItem><h4><b>{"\uFF0B"}</b> Neue Gruppe anlegen</h4>
                        </ListGroupItem>
                    </LinkContainer>
        );
    }


    renderLander() {
        return (
            <div className="landerusergroups">
                <h1>ASS</h1>
                <p>Attraktive Stadt Sonthofen</p>
            </div>
        );
    }

    renderUserGroups() {
        return (
            <div className="usergroups">
                <Panel bsStyle="info">
                    <Panel.Body>
                        <Panel.Title> <h2>Mitgliedergruppen verwalten</h2></Panel.Title>

                        <ListGroup>
                            {!this.state.isLoading && this.renderUserGroupsList(this.state.userGroups)}
                        </ListGroup>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }

    render() {
        return (
            <div className="ListUserGroup">
                {this.props.isAuthenticated ? this.renderUserGroups() : this.renderLander()}
            </div>
        );
    }

    decodeUserGroupe(usrGrp) {
        if (usrGrp === 'Admin_UserGroup') {
            return 'Administrator Gruppe'
        } if (usrGrp === 'Retailer_UserGroup') {
            return 'Händler'
        } if (usrGrp === 'Distributor_UserGroup') {
            return 'Ausgabestelle'
        }

        return 'unbekannt'

    }
}

export default ListUserGroups
