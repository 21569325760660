import React, { Component } from "react";
import { API, Auth } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel, Alert, Button, Form, Panel, HelpBlock } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import MyModal from "../../components/Modal";

import "./Validate.css";


export default class ValidateQRCode extends Component {
    constructor(props) {
        super(props);

        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleShow = this.handleShow.bind(this);

        this.state = {
            isLoading: null,
            batch: '',
            batchbegin: null,
            batchend: null,
            isOpen: false,
            show: false,
            message: ''
        };
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    validateForm() {
        return this.state.batchbegin !== null && (this.state.batchend === null || this.state.batchend > this.state.batchbegin || this.state.batchend === '');
    }

    async validateQRCode(batch) {
        var options
        await Auth.currentAuthenticatedUser().then((user) => {
            let jwtToken = user.signInUserSession.idToken.jwtToken;
            options = {
                headers: {
                    Authorization: jwtToken
                },
                body: batch
            }

        }).catch(error => {
            console.log(error.response)
        })
        await API.put("alpenstadt", "/validate", options).then(response => {
            this.setState({ message: response['message'] })
            return response;
        }).catch(error => {
            this.setState({ message: error.response['data']['ERROR'] })
            console.log(error.response)
        })
    }

    handleSubmit = async event => {
        event.preventDefault();
        this.setState({ isLoading: true })
        var end
        if (this.state.batchend === '' || !this.state.batchend) {
            end = this.state.batchbegin
        } else {
            end = this.state.batchend
        }

        try {
            console.log(this.state.batchbegin)
            console.log(end)
            await this.validateQRCode({
                batch: this.state.batch,
                batchbegin: parseInt(this.state.batchbegin),
                batchend: parseInt(end)
            });
            this.setState({ isOpen: true })
            this.setState({ isLoading: false })
            this.setState({ show: true })
        } catch (e) {
            alert(e);
            this.setState({ isOpen: true })
            this.setState({ isLoading: false })
            this.setState({ show: true })
        }
    }

    returnHome = event => {
        this.setState({ isOpen: false })
        this.setState({ batch: '' })
        this.setState({ batchbegin: '' })
        this.setState({ batchend: '' })
    }

    handleDismiss() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    render() {
        return (
            <div>
                <Panel bsStyle="primary">
                    <Panel.Heading>
                        <Panel.Title> <h1>Gutscheine aktivieren</h1></Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <Panel.Title> <h2>Einen oder mehrere Gutscheine aktivieren</h2></Panel.Title>
                        <p>Damit ein Gutschein eingelöst werden kann, benötigt dieser ein gültiges Kaufdatum. 
                            Dies wird durch die Aktivierung des Gutscheins erzeugt.</p>
                        <p><b>Wichtig: Der Kunde kann nur aktivierte Gutscheine einlösen!</b></p>
                        <p>
                            Ein Gutscheincode besteht aus drei Sektionen: <b>abc1-0000-ab340b281c</b>
                        </p>
                        <p>Die Charge wie z.B.: <b>abc1</b></p>
                        <p>Durchlaufende Gutscheinnummer z.B.: <b>0000</b></p>
                        <p>Einem zufälligen 10-stelligen Code z.B.: <b>ab340b281c</b></p>
                        <p>Um nur einen Gutschein zu aktivieren, ist es ausreichend, die durchlaufende Gutscheinnummer in das Feld <b>Gutschein von</b> (z.B. 0000) einzutragen.
                        <p>Sollen mehrere Gutscheine einer Charge aktiviert werden, kann ins Feld <b>Gutschein bis</b> (z.B. 0050) der letzte zu aktivierende Gutschein eingetragen werden.
                        Es werden dann die Gutscheine der Charge von 0000 bis 0050 aktiviert.</p>       
                        </p>
                        

                    </Panel.Body>
                    <Panel.Body>
                        <Form className="ValidateQRCode"
                            onSubmit={this.handleSubmit}>
                            {this.state.isOpen ?
                                <MyModal
                                    onClose={this.returnHome}
                                    message={this.state.message}
                                    buttonName="Schließen"
                                    header="Info">
                                </MyModal>
                                :
                                <br></br>
                            }
                            <FormGroup
                                controlId="batch"
                                bsSize="large"
                                className="inputbatchfrom">
                                <ControlLabel>Charge</ControlLabel>
                                <FormControl
                                    autoFocus
                                    onChange={this.handleChange}
                                    value={this.state.batch}
                                    type="text"
                                    placeholder="z.B. abc1"
                                />
                                <small id="batchhelp" class="form-text text-muted"><b>abc1</b>-0000-ab340b281c </small>
                            </FormGroup>

                            <FormGroup
                                controlId="batchbegin"
                                bsSize="large"
                                className="inputbatchfrom">
                                <ControlLabel>von</ControlLabel>
                                <FormControl
                                    onChange={this.handleChange}
                                    value={this.state.batchbegin}
                                    type="number"
                                    min="0"
                                    placeholder="z.B. 0000"
                                />
                                <small id="batchbeginhelp" class="form-text text-muted">abc1-<b>0000</b>-ab340b281c </small>
                            </FormGroup>

                            <FormGroup
                                controlId="batchend"
                                bsSize="large"
                                className="inputbatchfrom">
                                <ControlLabel>bis</ControlLabel>
                                <FormControl
                                    onChange={this.handleChange}
                                    value={this.state.batchend}
                                    type="number"
                                    min="0"
                                    placeholder="z.B. 0050"
                                />
                                <small id="batchbeginhelp" class="form-text text-muted">abc1-<b>0050</b>-ab340b281c </small>
                                
                            </FormGroup>

                            <LoaderButton
                                block
                                bsStyle="primary"
                                bsSize="large"
                                disabled={!this.validateForm()}
                                type="submit"
                                isLoading={this.state.isLoading}
                                text="Aktivieren"
                                loadingText="Aktivieren…"
                            />
                            <HelpBlock><p><b>Bitte aktiviere nicht mehr als 100 Gutscheine gleichzeitig.</b></p></HelpBlock>
                        </Form>
                    </Panel.Body>
                    {this.state.show ?
                        <Panel.Footer>
                            <Alert bsStyle="info" onDismiss={this.handleDismiss}>
                                <h4>Bitte beachten!</h4>
                                <p>
                                    {this.state.message}
                                </p>
                                <p>
                                    <Button onClick={this.handleDismiss}>Ok</Button>
                                </p>
                            </Alert>
                        </Panel.Footer>
                        :
                        <div></div>
                    }
                </Panel>
            </div>
        );
    }
}